import { MarketplacePolicyValidator } from './dialogs/edit-marketplace-policy/marketplace-policy.validator';
import { SalesCrossSellProductVariantComponent } from './components/ticket-sales/sales-crosssellproductvariant-selection/sales-crosssellproductvariant-selection.component';

import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';

import {
  CardModule,
  DatesModule,
  FormExtensionModule,
  LayoutsModule,
  ListViewModule,
  LoaderModule,
  LoggingService,
  OrderDetailsModule,
  SecondaryOrderDetailsModule,
  PageModule,
  PagerModule,
  PipesModule,
  T4ChartsModule,
  T4Validators,
  TabsModule,
  FileService,
  ControlsModule,
  localDatePipe,
  localDateTimePipe,
  localDateLongPipe,
  localTimePipe,

} from '@tymes4-shared';
//import {MomentModule} from 'angular2-moment';  tymes4v2 - not used
import {NgApexchartsModule} from 'ng-apexcharts';
import {ColorPickerModule} from 'ngx-color-picker';
import {QuillModule} from 'ngx-quill';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
//import {CalendarModule} from 'primeng/calendar'; tymes4v2 - not used
import {ProfileValidator} from '../views/administration/profile/profile.validator';
import {SystemSettingsEmailSettingsComponent} from '../views/administration/system-settings/system-settings-email-settings/system-settings-email-settings.component';
import {SystemSettingsConfigurationSettingsComponent} from '../views/administration/system-settings/system-settings-configuration-settings/system-settings-configuration-settings.component';
import {OrderConfirmComponent} from '../views/sales/order-confirm/order-confirm.component';
import {ActivityLogComponent} from './components/activity-log/activity-log.component';
import {ActivityLogDialogComponent} from './components/activity-log/dialog-activity-log/activity-log-dialog.component';
import {CardTurnoverComponent} from './components/card-turnover/card-turnover.component';
import {FileUploadComponent} from './components/common/file-upload/file-upload.component';
import {HelperService} from './components/common/helper-service';
import {NgxEditorModule} from './components/common/ngx-editor/ngx-editor.module';
import {DeliveryMethodGridComponent} from './components/delivery-method-grid/delivery-method-grid.component';
import {DialogHeaderComponent} from './components/dialog-header/dialog-header.component';
import {FileInputComponent} from './components/file-input/file-input.component';
import {LinkPricelistComponent} from './components/link-pricelist/link-pricelist.component';
import {LongRunningOperationProgressBarComponent} from './components/long-running-operation-progress-bar/long-running-operation-progress-bar.component';
import {CustomButtonStateFormComponent} from './components/manage-event-sale-category-states/custom-button-state-form/custom-button-state-form.component';
import {CustomButtonStateListComponent} from './components/manage-event-sale-category-states/custom-button-state-list/custom-button-state-list.component';
import {ManageEventSaleCategoryStatesComponent} from './components/manage-event-sale-category-states/manage-event-sale-category-states.component';
import {OrderOverviewWithBalanceComponent} from './components/order-overview-with-balance/order-overview-with-balance.component';
import {PaymentOverviewComponent} from './components/payment-overview/payment-overview.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {PurchaseRightComponent} from './components/purchase-right/purchase-right.component';
import {RepurchaseReservationsComponent} from './components/repurchase-reservations/repurchase-reservations.component';
import {OpenReservationsComponent} from './components/open-reservations/open-reservations.component';
import {ResellerContactsComponent} from './components/reseller-contacts/reseller-contacts.component';
import {ReservationHeaderComponent} from './components/reservation-header/reservation-header.component';
import {ReservationSelectionComponent} from './components/reservation-selection/reservation-selection.component';
import {StatisticsDashboardShopComponent} from './components/statistics-dashboard-shop/statistics-dashboard-shop.component';
import {StatisticsDashboardTargetsComponent} from './components/statistics-dashboard-targets/statistics-dashboard-targets.component';
import {SalesEventSelectionComponent} from './components/ticket-sales/sales-event-selection/sales-event-selection.component';
import {SalesMembershipSelectionComponent} from './components/ticket-sales/sales-membership-selection/sales-membership-selection.component';
import {SalesPassepartoutSelectionComponent} from './components/ticket-sales/sales-passepartout-selection/sales-passepartout-selection.component';
import {SalesSectionSelectionComponent} from './components/ticket-sales/sales-section-selection/sales-section-selection.component';
import {SectionSelectorComponent} from './components/venue-definition/section-selector/section-selector.component';
import {UnplacedTicketListComponent} from './components/venue-definition/unplaced-ticket-list/unplaced-ticket-list.component';
import {VenuePlacementsEditorComponent} from './components/venue-placements-editor/venue-placements-editor.component';
import {VenueRendererComponent} from './components/venue-renderer/venue-renderer.component';
import {VerticalTabComponent} from './components/vertical-tab/vertical-tab.component';
import {VerticalTabsComponent} from './components/vertical-tabs/vertical-tabs.component';
import {AddDiscountCodeFormComponent} from './dialogs/add-discount-code-form/add-discount-code-form.component';
import {AddPositionsComponent} from './dialogs/add-positions/add-positions.component';

import {AutoNumberComponent} from './dialogs/auto-number/auto-number.component';
import {PaymentComponent} from './dialogs/cash-register/payment/payment.component';
import {ChangeUnplacedContingentsComponent} from './dialogs/change-unplaced-contingents/change-unplaced-contingents.component';
import {CheckPurchaseRightComponent} from './dialogs/check-purchase-right/check-purchase-right.component';
import {ConfirmOrderModificationComponent} from './dialogs/confirm-order-modification/confirm-order-modification.component';
import {ConfirmOrderComponent} from './dialogs/confirm-order/confirm-order.component';
import {ConfirmReservationImportComponent} from './dialogs/confirm-reservation-import/confirm-reservation-import.component';
import {CreateInvoiceBatchComponent} from './dialogs/create-invoice-batch/create-invoice-batch.component';
import {CreatePrintBatchComponent} from './dialogs/create-print-batch/create-print-batch.component';
import {CreateRebuyExportComponent} from './dialogs/create-rebuy-export/create-rebuy-export.component';
import {CreateSeatGridComponent} from './dialogs/create-seat-grid/create-seat-grid.component';
import {CustomerPassePartoutHardcardsComponent} from './dialogs/customer-passepartout-hardcards/customer-passepartout-hardcards';
import {CustomerPassepartoutPositionsComponent} from './dialogs/customer-passepartout-positions/customer-passepartout-positions.component';
import {DownloadCustomerTagExportfileComponent} from './dialogs/download-customertag-exportfile/download-customertag-exportfile.component';
import {EditCustomerFormComponent} from './dialogs/edit-customer-form/edit-customer-form.component';
import {DeliveryMethodsValidator} from './dialogs/edit-delivery-methods-form/deliverymethods.validator';
import {EditDeliveryMethodsFormComponent} from './dialogs/edit-delivery-methods-form/edit-delivery-methods-form.component';
import {EditDiscountCodeFormComponent} from './dialogs/edit-discount-code-form/edit-discount-code-form.component';
import {EditDiscountFormComponent} from './dialogs/edit-discount-form/edit-discount-form.component';
import {EntranceValidator} from './dialogs/edit-entrance-form/entrance.validator';
import {ConfigurationSettingsValidator} from './dialogs/edit-configuration-form/configuration-settings.validator';
import {EditETicketTemplateFormComponent} from './dialogs/edit-etickettemplate-form/edit-etickettemplate-form.component';
import {ETicketTemplateValidator} from './dialogs/edit-etickettemplate-form/etickettemplate.validator';
import {EventValidator} from './dialogs/edit-event-form/event.validator';
import {EditFanclubFormComponent} from './dialogs/edit-fanclub-form/edit-fanclub-form.component';
import {EditInitiativeFormComponent} from './dialogs/edit-initiative-form/edit-initiative-form.component';
import {EditInitiativePricelistFormComponent} from './dialogs/edit-initiative-pricelist-form/edit-initiative-pricelist-form.component';
import {EditInitiativePromotionFormComponent} from './dialogs/edit-initiative-promotion-form/edit-initiative-promotion-form.component';
import { EditIpWhitelistingComponent } from './dialogs/edit-ip-whitelisting-form/edit-ip-whitelisting-form.component';
import {EditLinkedMembershipPriceListsComponent} from './dialogs/edit-linked-membership-pricelists/edit-linked-membership-pricelists.component';
import {EditLinkedPriceListsComponent} from './dialogs/edit-linked-pricelists/edit-linked-pricelists..component';
import {EditOrderLineFormComponent} from './dialogs/edit-orderline-form/edit-orderline-form.component';
import {EditPassePartoutFormComponent} from './dialogs/edit-passe-partout-form/edit-passe-partout-form.component';
import {PassePartoutValidator} from './dialogs/edit-passe-partout-form/passe-partout.validator';
import {EditPaymentMethodComponent} from './dialogs/edit-payment-method/edit-payment-method.component';
import {EditPricelistLinkComponent} from './dialogs/edit-pricelist-link/edit-pricelist-link.component';
import {EditPromotionFormComponent} from './dialogs/edit-promotion-form/edit-promotion-form.component';
import {EditPurchaseRightFormComponent} from './dialogs/edit-purchase-right-form/edit-purchase-right-form.component';
import {PurchaseRightValidator} from './dialogs/edit-purchase-right-form/purchase-right.validator';
import {EditReportUserTemplateFormComponent} from './dialogs/edit-report-user-template-form/edit-report-user-template-form.component';
import {ReportUserTemplateValidator} from './dialogs/edit-report-user-template-form/report-user-template.validator';
import {EditResellerContactComponent} from './dialogs/edit-reseller-contact/edit-reseller-contact.component';
import {EditSalesChannelComponent} from './dialogs/edit-sales-channel-form/edit-sales-channel-form.component';
import {EditSalesChannelTenantShopPopupComponent} from './dialogs/edit-sales-channel-tenant-shop-popup/edit-sales-channel-tenant-shop-popup.component';
import {EditSalesGroupComponent} from './dialogs/edit-sales-group-form/edit-sales-group-form.component';
import {EditSeatPlanComponent} from './dialogs/edit-seat-plan/edit-seat-plan.component';
import {TicketTextValidator} from './dialogs/edit-tickettext-form/tickettext.validator';
import {EditUnplacedTicketsComponent} from './dialogs/edit-unplaced-tickets/edit-unplaced-tickets.component';
import {EditVBBFormComponent} from './dialogs/edit-vbb-form/edit-vbb-form.component';
import {VBBValidator} from './dialogs/edit-vbb-form/vbb-validator';
import {EventPlacementDetailsComponent} from './dialogs/event-placement-details/event-placement-details.component';
import {FileInputDialogComponent} from './dialogs/file-input-dialog/file-input-dialog.component';
import {VenueSvgInputDialogComponent} from './dialogs/venue-svg-input-dialog/venue-svg-input-dialog.component';
import {GenerateDiscountCodesComponent} from './dialogs/generate-discount-codes/generate-discount-codes.component';
import {InvoiceDetailsComponent} from './dialogs/invoice-details/invoice-details.component';
import {ManualIdentificationComponent} from './dialogs/manual-identification/manual-identification.component';
import {ManualMailDialogComponent} from './dialogs/manual-mail-dialog/manual-mail-dialog.component';
import {NumberEntryFormComponent} from './dialogs/number-entry-form/number-entry-form.component';
import {OrderBlockedDetailsComponent} from './dialogs/order-blocked-details/order-blocked-details.component';
import {OrderDetailsComponent} from './dialogs/order-details/order-details.component';
import {OrderFailureDetailsComponent} from './dialogs/order-failure-details/order-failure-details.component';
import {PrintOrderHardcardsComponent} from './dialogs/print-order-hardcards/print-order-hardcards.component';
import {PrintPdfComponent} from './dialogs/print-pdf/print-pdf.component';
import {ReservationDetailsComponent} from './dialogs/reservation-details/reservation-details.component';
import {SeatPlanRowOffsetComponent} from './dialogs/seat-plan-row-offset/seat-plan-row-offset.component';
import {SeatplanLegendComponent} from './dialogs/seatplan-legend/seatplan-legend.component';
import {SelectEventComponent} from './dialogs/select-event/select-event.component';
import {SelectPendingorderDebtorComponent} from './dialogs/select-pendingorder-debtor/select-pendingorder-debtor.component';
import {SelectSeasonComponent} from './dialogs/select-season/select-season.component';
import {SelectTicketCustomerComponent} from './dialogs/select-ticket-customer/select-ticket-customer.component';
import {UploadCustomerTagImportfileComponent} from './dialogs/upload-customertag-importfile/upload-customertag-importfile.component';
import {UploadDiscountCodesImportfileComponent} from './dialogs/upload-discountcodes-importfile/upload-discountcodes-importfile.component';
import {UploadRepurchaseImportfileComponent} from './dialogs/upload-repurchase-importfile/upload-repurchase-importfile.component';
import {UploadOpenReservationsImportfileComponent} from './dialogs/upload-open-reservations-importfile/upload-open-reservations-importfile.component';
import {VbbUpdateEpDialogComponent} from './dialogs/vbb-update-ep-dialog/vbb-update-ep-dialog.component';
import { GenerateAccountingReportTemplateForm } from './dialogs/generate-accounting-report-template-form/generate-accounting-report-template-form.component';
import {currencyInputDirective} from './directives/currency-input.directive';
import {CurrencyFormatterDirective} from './directives/currencyformat-directive';
import {FillHeightDirective} from './directives/fill-height.directive';
// ALL TIME REQUIRED
// DIRECTIVES
import {MatchHeightDirective} from './directives/same-height.directive';
import {ToggleDirective} from './directives/toggle.directive';
import {ContactValidator} from './helpers/contact-validator';
import {ConversionHelper} from './helpers/conversion-helper';
import {GenericErrorHandler} from './helpers/generic-error-handler';
import {SeatPlanHelper} from './helpers/seatplan-helper';
import {SelectionHelper} from './helpers/selection-helper';
import {SnackbarHelper} from './helpers/snackbar-helper';
import {SystemEventHelper} from './helpers/system-event.helper';
import {TagValidator} from './helpers/tag-validator';
import {UserValidator} from './helpers/user-validator';
import {ValidationHelper} from './helpers/validation.helper';
import {PageTitleComponent} from './pagecomponents/page-title/page-title.component';
import {ActivitiyLogUserLinePipe} from './pipes/activitylog-user-line.pipe';
import {FileSizePipe} from './pipes/filesize.pipe';
import {LocalizedCurrencyPipe} from './pipes/localized-currency.pipe';
// PIPES
import {RelativeTimePipe} from '../../../../tymes4-shared/src/lib/pipes/relative-time.pipe';
import {VenueTitlePipe} from './pipes/venue-title.pipe';
import {LongRunningOperationComponent} from './services/app-long-running-operation-status/app-long-running-operation-status';
import {LongRunningOperationStatusService} from './services/app-long-running-operation-status/app-long-running-operation-status.service';
import {AuthService} from './services/auth.service';
import {AuthGuard} from './services/auth/auth.guard';
import {ArchiveGuard} from './services/auth/archive.guard';
import {CashRegisterStateService} from './services/cash-register-state.service';
import {ConstService} from './services/const.service';
import {ConfigServiceOld} from './services/http/config.service';

import {ImportService} from './services/http/import.service';
import {PromotionService} from './services/http/promotion.service';

// SERVICES
import {LocalizationService} from './services/localization.service';
import {SalesEntityModificationStateService} from './services/sales-entity-modification-state.service';
import {SalesHelperService} from './services/sales-helper.service';
import {MailTemplateValidator} from './validators/mail-template-validator';
import {OptInValidator} from './validators/opt-in-validator';
import {PriceComponentSchemeValidator} from './validators/price-component-scheme-validator';
import {ReservationTypeValidator} from './validators/reservation-type-validator';
import {SalesChannelValidator} from './validators/sales-channel-validator';
import {SalesGroupValidator} from './validators/sales-group-validator';

import { TreeModule } from 'angular-tree-component';
import { OrderImportComponent } from './components/order-import/order-import.component';
import { CreateOrderImportFileComponent } from './dialogs/create-order-import-file/create-order-import-file.component';
import { UploadOrderImportFileComponent } from './dialogs/upload-order-importfile/upload-order-importfile.component';
import { UploadExternalTicketsImportfileComponent } from './dialogs/upload-external-tickets-importfile/upload-external-tickets-importfile.component';
import { ConfirmOrderImportComponent } from './dialogs/confirm-order-import/confirm-reservation-import.component';
import { EditTemplateFormComponent } from '../views/administration/templates/components/edit-template-form/edit-template-form.component';
import { TemplateValidator } from '../views/administration/templates/components/edit-template-form/template.validator';
import { RemoveDiscountCodeFormComponent } from './dialogs/remove-discount-code-form/remove-discount-code-form.component';
import { CustomerSelectorComponent } from './components/customer/customer-selector/customer-selector.component';
import { CustomerSearchDialogComponent } from './components/customer/customer-search-dialog/customer-search-dialog.component';
import { CustomerBarcodeSearchDialogComponent } from './components/customer/customer-barcode-search-dialog/customer-barcode-search-dialog.component';
import { CustomerSearchComponent } from './components/customer/customer-search/customer-search.component';
import { PersistedSearchService } from './services/persisted-search.service';
import { UploadMembershipImportFileComponent } from './dialogs/upload-membership-importfile/upload-membership-importfile.component';
import { ConfirmMembershipImportComponent } from './dialogs/confirm-membership-import/confirm-membership-import.component';

import { PassepartoutEventQueueComponent } from './components/passepartout-event-queue/passepartout-event-queue.component';
import { DatabaseScaleUpService } from './services/http/database-scaleup.service';

import { CrossSellCategoryValidator } from './dialogs/edit-crosssell-category/crosssellcategory.validator';
import { ImagesListviewComponent } from './components/images-listview/images-listview.component';
import { EditMediaFormComponent } from './dialogs/edit-media-form/edit-media-form.component';
import { UploadMultipleImagesComponent } from './dialogs/upload-multiple-images/upload-multiple-images.component';
import { EditCrossSellProductEventComponent } from './dialogs/edit-crosssell-product-event/edit-crosssell-product-event.component';
import { CrossSellProductEventComponent } from '../views/crosssell/crosssellproduct-event/crosssellproduct-event.component';
import { EditCrossSellProductVariantComponent } from './dialogs/edit-crosssell-product-variant/edit-crosssell-product-variant.component';
import { VenueEditChangeLogComponent } from './dialogs/venue-edit-change-log/venue-edit-change-log.component';
import {PriceVariationValidator} from './dialogs/edit-price-variation-form/price-variation.validator';
import { EditUserFormComponent } from './dialogs/edit-user-form/edit-user-form.component';
import { EditPriceFormComponent } from './dialogs/edit-price-form/edit-price-form.component';
import { SecondaryOrderDetailsComponent } from './dialogs/secondary-order-details/secondary-order-details.component';

import { CreateMergeBatchComponent } from './dialogs/create-merge-batch/create-merge-batch.component';
import { MergeCustomerDialogComponent } from './dialogs/merge-customer/merge-customer-dialog.component';
import { BASE_BO_CONFIG } from '../injectors';
import {ApplicationModuleHelperService} from "./services/application-module.service";
import { LroButton } from './components/lro-button/lro-button.component';
import { OptInQuestionService, ReservationTypeService, SubscriptionTypeService, TicketTextService, TranslationService } from './api';
import { AccountingDimensionHelperService } from './services/accounting-dimension.service';
import { AccountingDimensionsComponent } from './components/accounting-dimensions/accounting-dimensions.component';
import { UploadCustomerloyaltytagImportfileComponent } from './dialogs/upload-customerloyaltytag-importfile/upload-customerloyaltytag-importfile.component';
import { CreateMembershipBatchFormComponent } from './dialogs/create-membership-batch-form/create-membership-batch-form.component';
import { CustomerMatchdaypassDetailComponent } from './dialogs/customer-matchdaypass-details/customer-matchdaypass-details.component';
import { MembershipBatchCollectFormComponent } from './dialogs/membership-batch-collect-form/membership-batch-collect-form.component';
import { SelectMembershipRenewalDateComponent } from './dialogs/select-membership-renewal-date/select-membership-renewal-date.component';
import { MembershipRenewalImportFileDetailsComponent } from './dialogs/membership-renewal-import-file-details/membership-renewal-import-file-details.component';
import { PricesComponent } from '../views/matchdaypass/matchdaypass-details/prices/prices.component';
import { UploadExternalTicketsComponent } from './dialogs/upload-external-tickets/upload-external-tickets.component';
import {
  SalesMatchdaypassSelectionComponent
} from "./components/ticket-sales/sales-matchdaypass-selection/sales-matchdaypass-selection.component";
import {SalesSectorDetailsComponent} from "./dialogs/sales-sector-details/sales-sector-details.component";
import { AssignmentPolicyValidator } from './dialogs/edit-assignment-policy/assignment-policy.validator';
import { EditTenantShopComponent } from './dialogs/edit-tenant-shop-form/edit-tenant-shop-form.component';
import { OrderDetailActionsComponent } from './components/order-details/order-detail-actions/order-detail-actions.component';
import { OrderDetailDetailsComponent } from './components/order-details/order-detail-details/order-detail-details.component';
import { OrderDetailTabsComponent } from './components/order-details/order-detail-tabs/order-detail-tabs.component';
import { EditOrderLineComponent } from './dialogs/edit-order-line/edit-order-line.component';
import { OrderLinePositionDialogComponent } from './components/order-details/order-line-position-dialog/order-line-position-dialog.component';
import { OrderLinePriceDialogComponent } from './components/order-details/order-line-price-dialog/order-line-price-dialog.component';
import { OrderReferenceDialogComponent } from './dialogs/order-reference-dialog/order-reference-dialog.component';
import { OrderMutationsComponent } from './components/order-mutations/order-mutations.component';
import { OrderRevisionDetailsComponent } from './dialogs/order-revision-details/order-revision-details.component';
import { EventAssignmentValidator } from './validators/event-assignment-validator';
import { CreditDetailsDialog } from './dialogs/credit-details-dialog/credit-details-dialog.component';
import { CreditActionsDropdown } from './dialogs/credit-actions-dropdown/credit-actions-dropdown.component';
import { CreditTransactionDialog } from './dialogs/credit-transaction-dialog/credit-transaction-dialog.component';

const classesToInclude = [

  //Directives
  CurrencyFormatterDirective,

  MatchHeightDirective,
  FillHeightDirective,

  //Shared Components
  EditUserFormComponent,
  EditPriceFormComponent,
  LongRunningOperationComponent,

  //Pipes
  FileSizePipe,
  LocalizedCurrencyPipe,
  RelativeTimePipe,
  VenueTitlePipe,


  SectionSelectorComponent,
  CreateSeatGridComponent,
  EditSeatPlanComponent,
  AutoNumberComponent,
  SeatPlanRowOffsetComponent,
  SeatplanLegendComponent,

  ConfirmOrderComponent,
  EditOrderLineComponent,
  OrderDetailsComponent,
  OrderDetailActionsComponent,
  OrderDetailDetailsComponent,
  OrderDetailTabsComponent,
  OrderReferenceDialogComponent,
  OrderMutationsComponent,
  SecondaryOrderDetailsComponent,
  ReservationDetailsComponent,
  EditOrderLineFormComponent,
  PrintOrderHardcardsComponent,
  SelectTicketCustomerComponent,
  FileUploadComponent,
  EventPlacementDetailsComponent,
  EditReportUserTemplateFormComponent,
  OrderFailureDetailsComponent,
  OrderBlockedDetailsComponent,
  OrderRevisionDetailsComponent,
  CreditActionsDropdown,
  CreditDetailsDialog,
  CreditTransactionDialog,
  PaymentOverviewComponent,
  EditCustomerFormComponent,
  EditPassePartoutFormComponent,
  ReservationSelectionComponent,
  SelectPendingorderDebtorComponent,
  OrderOverviewWithBalanceComponent,
  PaymentOverviewComponent,
  PaymentComponent,
  PrintPdfComponent,
  AddPositionsComponent,
  SalesSectionSelectionComponent,
  SalesEventSelectionComponent,
  SalesPassepartoutSelectionComponent,
  ReservationHeaderComponent,
  ConfirmOrderModificationComponent,
  FileInputComponent,
  FileInputDialogComponent,
  VenueSvgInputDialogComponent,
  ManualMailDialogComponent,
  EditPricelistLinkComponent,
  EditResellerContactComponent,
  MembershipBatchCollectFormComponent,
  LinkPricelistComponent,
  ResellerContactsComponent,
  ManualIdentificationComponent,
  CustomerPassePartoutHardcardsComponent,
  CustomerPassepartoutPositionsComponent,
  DeliveryMethodGridComponent,
  CreatePrintBatchComponent,
  ManageEventSaleCategoryStatesComponent,
  PurchaseRightComponent,
  EditPurchaseRightFormComponent,
  LongRunningOperationProgressBarComponent,
  ActivitiyLogUserLinePipe,
  VenuePlacementsEditorComponent,
  UnplacedTicketListComponent,
  EditPromotionFormComponent,
  EditInitiativeFormComponent,
  SalesMembershipSelectionComponent,
  SalesCrossSellProductVariantComponent,
  SalesMatchdaypassSelectionComponent,
  SalesSectorDetailsComponent,
  EditLinkedMembershipPriceListsComponent,
  EditDiscountFormComponent,
  SystemSettingsEmailSettingsComponent,
  SystemSettingsConfigurationSettingsComponent,
  EditSalesChannelComponent,
  EditSalesChannelTenantShopPopupComponent,
  EditSalesGroupComponent,
  PageTitleComponent,
  EditFanclubFormComponent,
  EditTemplateFormComponent,
  EditCrossSellProductEventComponent,
  EditCrossSellProductVariantComponent,
  CrossSellProductEventComponent,
  GenerateAccountingReportTemplateForm,
  EditTenantShopComponent,

  //HYS
  CreateMembershipBatchFormComponent,
  CustomerMatchdaypassDetailComponent,
  SelectMembershipRenewalDateComponent,
  MembershipRenewalImportFileDetailsComponent,
  PricesComponent,

  // controls
  StatisticsDashboardShopComponent,
  StatisticsDashboardTargetsComponent,
  VerticalTabsComponent,
  VerticalTabComponent,

  LroButton,

  LongRunningOperationProgressBarComponent,
  EditETicketTemplateFormComponent,

  SelectEventComponent,
  CustomButtonStateFormComponent,
  CustomButtonStateListComponent,
  CheckPurchaseRightComponent,
  ToggleDirective,
  CreateInvoiceBatchComponent,
  InvoiceDetailsComponent,
  RepurchaseReservationsComponent,
  OpenReservationsComponent,
  OrderImportComponent,
  CreateRebuyExportComponent,
  CreateOrderImportFileComponent,
  UploadRepurchaseImportfileComponent,
  UploadOpenReservationsImportfileComponent,
  UploadOrderImportFileComponent,
  UploadExternalTicketsImportfileComponent,
  ActivityLogComponent,
  ActivityLogDialogComponent,
  AccountingDimensionsComponent,
  ProgressBarComponent,
  VenueRendererComponent,
  OrderConfirmComponent,
  UploadCustomerTagImportfileComponent,
  UploadCustomerloyaltytagImportfileComponent,
  VbbUpdateEpDialogComponent,
  ChangeUnplacedContingentsComponent,
  DownloadCustomerTagExportfileComponent,
  EditInitiativePromotionFormComponent,
  EditInitiativePricelistFormComponent,
  DownloadCustomerTagExportfileComponent,
  CardTurnoverComponent,
  EditLinkedPriceListsComponent,
  EditUnplacedTicketsComponent,
  EditVBBFormComponent,
  VenueEditChangeLogComponent,
  EditPaymentMethodComponent,
  EditIpWhitelistingComponent,
  ConfirmReservationImportComponent,
  ConfirmOrderImportComponent,
  EditDeliveryMethodsFormComponent,
  EditLinkedMembershipPriceListsComponent,
  SelectSeasonComponent,
  NumberEntryFormComponent,
  UploadDiscountCodesImportfileComponent,
  AddDiscountCodeFormComponent,
  DialogHeaderComponent,
  GenerateDiscountCodesComponent,
  EditDiscountCodeFormComponent,
  RemoveDiscountCodeFormComponent,
  PassepartoutEventQueueComponent,
  CustomerSelectorComponent,
  CustomerSearchComponent,
  CustomerSearchDialogComponent,
  CustomerBarcodeSearchDialogComponent,
  OrderLinePositionDialogComponent,
  OrderLinePriceDialogComponent,
  UploadMembershipImportFileComponent,
  ConfirmMembershipImportComponent,

  ImagesListviewComponent,
  EditMediaFormComponent,
  UploadMultipleImagesComponent,

  CreateMergeBatchComponent,
  MergeCustomerDialogComponent,
  UploadExternalTicketsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    NgxEditorModule,
    //CalendarModule,  tymes4v2 - not used
    //MomentModule,  tymes4v2 - not used
    NgxTrimDirectiveModule,
    NgApexchartsModule,
    ColorPickerModule,
    TreeModule.forRoot(),
    NgSelectModule,
    QuillModule,
    LayoutsModule,
    PipesModule,
    LoaderModule,
    PagerModule,
    ListViewModule,
    FormExtensionModule,
    TabsModule,
    DatesModule,
    PageModule,
    OrderDetailsModule,
    SecondaryOrderDetailsModule,
    CardModule,
    T4ChartsModule,
    ControlsModule,
  ],
  providers: [

    //TO CHECK
    PassepartoutEventQueueComponent,
    ConfirmMembershipImportComponent,
    ConfirmOrderImportComponent,
    ConfirmReservationImportComponent,

    //HANDLERS
    GenericErrorHandler,

    //HELPERS
    CashRegisterStateService,
    ApplicationModuleHelperService,
    AccountingDimensionHelperService,
    ConversionHelper,
    LongRunningOperationStatusService,
    SalesEntityModificationStateService,
    SalesHelperService,
    SeatPlanHelper,
    SelectionHelper,
    SnackbarHelper,
    SystemEventHelper,
    ValidationHelper,

    //HELPER-SERVICES
    AuthGuard,
    ArchiveGuard,
    AuthService,
    ConfigServiceOld,
    ConstService,
    FileService,
    LocalizationService,
    LoggingService,
    PersistedSearchService,

    //HTTP-SERVICES
    DatabaseScaleUpService,
    HelperService,
    ImportService,
    PromotionService,

    TicketTextService,

    //PIPES
    ActivitiyLogUserLinePipe,
    LocalizedCurrencyPipe,
    CurrencyPipe,
    DatePipe,
    localDatePipe,
    localDateTimePipe,
    localDateLongPipe,
    localTimePipe,

    //VALIDATORS
    ConfigurationSettingsValidator,
    ContactValidator,
    CrossSellCategoryValidator,
    DeliveryMethodsValidator,
    EntranceValidator,
    ETicketTemplateValidator,
    EventValidator,
    MailTemplateValidator,
    MarketplacePolicyValidator,
    AssignmentPolicyValidator,
    OptInValidator,
    PassePartoutValidator,
    PriceComponentSchemeValidator,
    PriceVariationValidator,
    ProfileValidator,
    PurchaseRightValidator,
    ReportUserTemplateValidator,
    ReservationTypeValidator,
    SalesChannelValidator,
    SalesGroupValidator,
    T4Validators,
    TagValidator,
    TemplateValidator,
    EventAssignmentValidator,

    CurrencyPipe,
    HelperService,
    ImportService,

    SystemEventHelper,
    ValidationHelper,
    SelectionHelper,
    FileService,
    ReservationTypeService,
    ConversionHelper,
    OptInQuestionService,
    TranslationService,
    SubscriptionTypeService,
    LocalizedCurrencyPipe,
    DatePipe,
    GenericErrorHandler,
    PassePartoutValidator,
    ActivitiyLogUserLinePipe,
    SnackbarHelper,
    SeatPlanHelper,
    ConfirmReservationImportComponent,
    ConfirmOrderImportComponent,
    ConfirmMembershipImportComponent,
    PassepartoutEventQueueComponent,
    DatabaseScaleUpService,

    DatabaseScaleUpService,

    DatabaseScaleUpService,
    TicketTextValidator,
    UserValidator,
    VBBValidator,
  ],
  declarations: classesToInclude,
  entryComponents: [
    CreateSeatGridComponent,
    EditSeatPlanComponent,
    SeatPlanRowOffsetComponent,
    LongRunningOperationComponent,
    ConfirmOrderComponent,
    EditOrderLineComponent,
    OrderDetailsComponent,
    OrderDetailActionsComponent,
    OrderDetailDetailsComponent,
    OrderDetailTabsComponent,
    OrderReferenceDialogComponent,
    OrderMutationsComponent,
    SecondaryOrderDetailsComponent,
    ReservationDetailsComponent,
    SelectTicketCustomerComponent,
    EditOrderLineFormComponent,
    EditPaymentMethodComponent,
    EditIpWhitelistingComponent,
    PrintOrderHardcardsComponent,
    EventPlacementDetailsComponent,
    EditReportUserTemplateFormComponent,
    OrderFailureDetailsComponent,
    OrderBlockedDetailsComponent,
    OrderRevisionDetailsComponent,
    CreditActionsDropdown,
    CreditDetailsDialog,
    CreditTransactionDialog,
    EditCustomerFormComponent,
    EditPassePartoutFormComponent,
    SelectPendingorderDebtorComponent,
    PaymentComponent, PrintPdfComponent,
    AddPositionsComponent,
    ConfirmOrderModificationComponent,
    EditPricelistLinkComponent,
    EditResellerContactComponent,
    ManualIdentificationComponent,
    FileInputDialogComponent,
    VenueSvgInputDialogComponent,
    CustomerPassePartoutHardcardsComponent,
    CustomerPassepartoutPositionsComponent,
    CreatePrintBatchComponent,
    PurchaseRightComponent,
    EditPurchaseRightFormComponent,
    EditETicketTemplateFormComponent,
    SelectEventComponent,
    CustomButtonStateFormComponent,
    CustomButtonStateListComponent,
    CheckPurchaseRightComponent,
    CreateInvoiceBatchComponent,
    InvoiceDetailsComponent,
    RepurchaseReservationsComponent,
    OpenReservationsComponent,
    OrderImportComponent,
    CreateRebuyExportComponent,
    CreateOrderImportFileComponent,
    UploadRepurchaseImportfileComponent,
    UploadOpenReservationsImportfileComponent,
    UploadOrderImportFileComponent,
    UploadExternalTicketsImportfileComponent,
    ActivityLogDialogComponent,
    EditPromotionFormComponent,
    EditInitiativeFormComponent,
    VbbUpdateEpDialogComponent,
    ChangeUnplacedContingentsComponent,
    SeatplanLegendComponent,
    UploadCustomerTagImportfileComponent,
    UploadCustomerloyaltytagImportfileComponent,
    EditInitiativePromotionFormComponent,
    EditInitiativePricelistFormComponent,
    EditLinkedPriceListsComponent,
    EditUnplacedTicketsComponent,
    EditVBBFormComponent,
    VenueEditChangeLogComponent,
    ConfirmReservationImportComponent,
    ConfirmOrderImportComponent,
    EditDeliveryMethodsFormComponent,
    EditLinkedMembershipPriceListsComponent,
    AutoNumberComponent,
    SelectSeasonComponent,
    EditDiscountFormComponent,
    NumberEntryFormComponent,
    UploadDiscountCodesImportfileComponent,
    AddDiscountCodeFormComponent,
    ManualMailDialogComponent,
    SelectSeasonComponent,
    GenerateDiscountCodesComponent,
    EditDiscountCodeFormComponent,
    EditSalesChannelComponent,
    EditSalesChannelTenantShopPopupComponent,
    EditSalesGroupComponent,
    EditFanclubFormComponent,
    EditTemplateFormComponent,
    RemoveDiscountCodeFormComponent,
    CustomerSearchDialogComponent,
    CustomerBarcodeSearchDialogComponent,
    OrderLinePositionDialogComponent,
    OrderLinePriceDialogComponent,
    UploadMembershipImportFileComponent,
    ConfirmMembershipImportComponent,
    PassepartoutEventQueueComponent,
    EditMediaFormComponent,
    UploadMultipleImagesComponent,
    EditCrossSellProductEventComponent,
    EditCrossSellProductVariantComponent,
    GenerateAccountingReportTemplateForm,
    EditTenantShopComponent,
    EventAssignmentValidator,
    CreateMergeBatchComponent,
    MergeCustomerDialogComponent,
  ],
  exports: classesToInclude,
})
export class SharedModule {
}
