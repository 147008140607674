import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpLoaderService, SearchDataRequest } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { AssignmentHistoryView, AssignmentHistoryViewPagedResult, AssignmentService, IdentificationStateEnum, TicketHistoryCurrentView, TicketSwapStatusEnum } from "../../../api";
import { ApplicationModuleHelperService } from '../../../services/application-module.service';


@Component({
  selector: 'app-ticket-assignment-history',
  templateUrl: './ticket-assignment-history.component.html',
  styleUrls: ['./ticket-assignment-history.component.scss']
})
export class TicketAssignmentHistoryComponent implements OnInit {

  @ViewChild('listVw') listVw;

  public listOptions = {
    search: false,
    showBarcodeSearch: false,
    pageSize: 8,
    fixedTableLayout: true,
    disableSort: true,
    headers: [
      { sortField: 'Created', label: 'RELATIONS.CUSTOMERCARD.TICKET-ASSIGNMENT-HISTORY.LISTVIEW.HEADER.DATE', isDefaultSort: 'desc', width: 90 },
      { label: 'RELATIONS.CUSTOMERCARD.TICKET-ASSIGNMENT-HISTORY.LISTVIEW.HEADER.TIME', width: 60 },
      { sortField: 'RELATIONS.CUSTOMERCARD.TICKET-ASSIGNMENT-HISTORY.LISTVIEW.HEADER.STATUS', label: 'ACTION / STATUS', width: 150 },
      { sortField: 'By', label: 'RELATIONS.CUSTOMERCARD.TICKET-ASSIGNMENT-HISTORY.LISTVIEW.HEADER.BY', width: 180 },
      { sortField: 'To', label: 'RELATIONS.CUSTOMERCARD.TICKET-ASSIGNMENT-HISTORY.LISTVIEW.HEADER.TO', width: 180 },
      { sortField: 'Barcode', label: 'RELATIONS.CUSTOMERCARD.TICKET-ASSIGNMENT-HISTORY.LISTVIEW.HEADER.BARCODE', width: 200 }
    ]
  };

  public mostRecentItem: TicketHistoryCurrentView = {};
  public eventPlacementId: number;
  public customerId: number;
  public identificationStatus: any;
  public TicketSwapStatusEnum = TicketSwapStatusEnum;
  public isIbaActive: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: { eventPlacementId: number, customerId: number },
    public dialogRef: MatDialogRef<TicketAssignmentHistoryComponent>,
    private assignmentService: AssignmentService,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private loader: HttpLoaderService,
  ) {
    this.eventPlacementId = passedData.eventPlacementId;
    this.customerId = passedData.customerId;
  }

  ngOnInit(): void {
    this.loader.open();
    this.assignmentService.getLatestAssignmentHistory(this.eventPlacementId).subscribe((resp: any) => {
      this.loader.close();
      this.mostRecentItem = resp;
    })

    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    })
  }

  OnDataRequest(e: SearchDataRequest) {
    this.assignmentService.searchAssigments(this.customerId, this.eventPlacementId, e.searchText, e.pageNr, e.pageSize, e.sortField, e.sortAsc, null).subscribe((resp: AssignmentHistoryViewPagedResult) => {
      this.listVw.Data = resp;
    });
  }

  constructCustomerIdentificationModel(isTicketHolder: boolean) {
    if (this.mostRecentItem) {
      return {
        CalculatedName: isTicketHolder ? this.mostRecentItem.TicketHolderName : this.mostRecentItem.BoughtByName,
        IsIdentified: isTicketHolder ? this.mostRecentItem.TicketHolderIsIdentified : this.mostRecentItem.BoughtByIsIdentified,
        CustomerNumber: isTicketHolder ? this.mostRecentItem.TicketHolderNumber : this.mostRecentItem.BoughtByNumber,
        CustomerId: isTicketHolder ? this.mostRecentItem.TicketHolderId : this.mostRecentItem.BoughtById,
      }
    }
  }

  constructSeondaryCustomerIdentificationModel() {
    if (this.mostRecentItem) {
      return {
        CalculatedName: this.mostRecentItem.SecondaryBoughtByName,
        IsIdentified: this.mostRecentItem.SecondaryBoughtByIsIdentified,
        CustomerNumber:  this.mostRecentItem.SecondaryBoughtByNumber,
        CustomerId:  this.mostRecentItem.SecondaryBoughtById,
      }
    }
  }

  getIdentificationIconClass(isTicketHolder: boolean): string {
    if (!this.mostRecentItem) {
      return '';
    }

    let statusToCheck: IdentificationStateEnum = isTicketHolder ? this.mostRecentItem.TicketHolderIdentificationStatus : this.mostRecentItem.BoughtByIdentificationStatus;

    switch (statusToCheck) {
      case IdentificationStateEnum.NotStarted:
        return 'fas fa-file-upload text-warning pr-2';
      case IdentificationStateEnum.PendingCheck:
        return 'fas fa-clock text-warning pr-2';
      case IdentificationStateEnum.BeingChecked:
        return 'fas fa-clock text-warning pr-2';
      case IdentificationStateEnum.Verified:
        return 'fas fa-check-circle text-success pr-2';
      case IdentificationStateEnum.Rejected:
        return 'fas fa-exclamation-circle text-danger pr-2';
    }
  }

  getStatusValue(status: TicketSwapStatusEnum) {
    switch (status) {
      case TicketSwapStatusEnum.None:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.NONE";
      case TicketSwapStatusEnum.Offered:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.OFFERED";
      case TicketSwapStatusEnum.Accepted:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ACCEPTED";
      case TicketSwapStatusEnum.Sold:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.SOLD";
      case TicketSwapStatusEnum.Withdrawn:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.WITHDRAW";
      case TicketSwapStatusEnum.Revoked:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.REVOKED";
      case TicketSwapStatusEnum.PendingInvitation:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ASSIGNMENTINVITED";
      case TicketSwapStatusEnum.InvitationDeclined:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.DECLINED";
      case TicketSwapStatusEnum.Assigned:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ASSIGNED";
      case TicketSwapStatusEnum.AssignmentRequired:
        return "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ASSIGNMENT-REQUIRED";
      default:
        return "";
    }
  }
}