import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HttpLoaderService } from '@tymes4-shared';
import { TenantShopService } from '../../api';

@Component({
  selector: 'app-edit-sales-channel-tenant-shop-popup',
  templateUrl: './edit-sales-channel-tenant-shop-popup.component.html',
  styleUrls: ['./edit-sales-channel-tenant-shop-popup.component.scss']
})
export class EditSalesChannelTenantShopPopupComponent implements OnInit {
  public form: FormGroup;
  public editObject: any = null;

  constructor(
    public dialogRef: MatDialogRef<EditSalesChannelTenantShopPopupComponent>,
    private loader: HttpLoaderService,
    private tenantShopService: TenantShopService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      Name: new FormControl(''),
      Subdomain: new FormControl(''),
      Active: new FormControl(true)
    });

    this.form.patchValue(this.editObject);
  }

  submit() {
    let formData = this.form.value;

    this.loader.open();

    this.tenantShopService.createTenantShop(formData).subscribe((id) => {
      this.dialogRef.close(id);
      this.loader.close();
    })


  }

}
