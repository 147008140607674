<!-- MODAL HEADER-->
<div style="overflow: hidden;">
    <div class="row modal-bar">
  
    <div class="col-sm-6">
        <h5 class="modal-title">{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.TITLE' | translate }}</h5>
    </div>
  
    <div class="col-sm-6">
        <div class="modal-actions">
            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
    </div>
  
    <form class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
        <div class="row">
          <div class="col-lg-3">
  
            <div style="pointer-events: none;">
              <button class="btn btn-block blue bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.None">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.NONE" | translate }}</span>
              </button>
              <button class="btn btn-block orange bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.Offered">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.OFFERED" | translate }}</span>
              </button>
              <button class="btn btn-block green bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.Accepted">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ACCEPTED" | translate }}</span>
              </button>
              <button class="btn btn-block green bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.Sold">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.SOLD" | translate }}</span>
              </button>
              <button class="btn btn-block green bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.Withdrawn">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.WITHDRAW" | translate }}</span>
              </button>
              <button class="btn btn-block red bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.Revoked">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.REVOKED" | translate }}</span>
              </button>
              <button class="btn btn-block orange bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.PendingInvitation">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ASSIGNMENTINVITED" | translate }}</span>
              </button>
              <button class="btn btn-block red bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.InvitationDeclined">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.DECLINED" | translate }}</span>
              </button>
              <button class="btn btn-block green bd-0" *ngIf="mostRecentItem.Status == TicketSwapStatusEnum.Assigned">
                <span>{{ "RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.STATUS.ASSIGNED" | translate }}</span>
              </button>
            </div>
  
            <br>
  
            <div class="row">
              <div class="col-lg-12">
                <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.TICKETHOLDER' | translate }}</span>
              </div>         
            </div>
            <div class="row">
                <div class="col-lg-12">
                  <span [ngClass]="getIdentificationIconClass(true)"></span>
                  <span><b>
                    <t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(true)"></t4-customer-identification-control>
                  </b></span>
                </div>
            </div>

            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">

            <div class="row">
                <div class="col-lg-12">
                  <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.BOUGHTBY' | translate }}</span>
                </div>         
            </div>
            <div class="row">
                <div class="col-lg-12">
                  <span [ngClass]="getIdentificationIconClass(false)"></span>
                  <span><b>
                    <t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructCustomerIdentificationModel(false)"></t4-customer-identification-control>
                  </b></span>
                </div>
            </div>

            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">

            <div class="row">
                <div class="col-lg-12">
                  <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.SECONDARYBOUGHTBY' | translate }}</span>
                </div>         
            </div>
            <div class="row">
                <div class="col-lg-12">
                  <span [ngClass]="getIdentificationIconClass(false)"></span>
                  <span><b>
                    <t4-customer-identification-control [isIbaActive]="isIbaActive" [showCustomerNumber]="true" [customer]="constructSeondaryCustomerIdentificationModel()"></t4-customer-identification-control>
                  </b></span>
                </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
            <div class="row">
              <div class="col-lg-4">
                <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.BARCODE' | translate }}</span>
              </div>
              <div class="col-lg-8">
                <b>{{ mostRecentItem.Barcode | replaceOnEmpty }}</b>
              </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
            <div class="row">
              <div class="col-lg-4">
                <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.EVENT' | translate }}</span>
              </div>
              <div class="col-lg-8">
                <b>{{ mostRecentItem.EventName | replaceOnEmpty }}</b>
              </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
            <div class="row">
              <div class="col-lg-4">
                <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.DATETIME' | translate }}</span>
              </div>
              <div class="col-lg-8">
                <b>{{ mostRecentItem.Created | localDate | replaceOnEmpty }} / {{ mostRecentItem.Created | localTime | replaceOnEmpty }}</b>
              </div>
            </div>
  
            <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">

            <div class="row">
              <div class="col-lg-4">
                <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.BLOCK' | translate }}</span>
              </div>
              <div class="col-lg-8">
                <b>{{ mostRecentItem.SectionName | replaceOnEmpty }}</b>
              </div>
            </div>
    
              <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">

              <div class="row">
                <div class="col-lg-4">
                  <span>{{ 'RELATIONS.TICKET-ASSIGNMENT-HISTORY-DIALOG.ROWSEAT' | translate }}</span>
                </div>
                <div class="col-lg-8">
                  <b>{{ mostRecentItem.RowNumber | replaceOnEmpty }} / {{ mostRecentItem.SeatNumber | replaceOnEmpty }}</b>
                </div>
              </div>
    
              <hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
  
          </div>
          <div class="col-lg-9">
            <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">
            
              <ng-container *t4ListItem="let item">
            
                <td>{{ item.Created | localDateTime | slice:0:10 }}</td>
                <td>{{ item.Created | localTime }}</td>
                <td>{{ getStatusValue(item.Status) | translate }}</td>
                <td>{{ item.ByCalculated }}</td>
                <td>{{ item.ToCalculated }}</td>
                <td>{{ item.Barcode | replaceOnEmpty }}</td>
            
              </ng-container>
            
            </t4-list-view>
          </div>
        </div>
      </div><!-- form-layout -->
    </div>  
    </form>
  </div>