/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PassePartout { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Active?: boolean;
    Name?: string | null;
    Code?: string | null;
    Named?: boolean;
    SeasonId?: number | null;
    IsUniquelyAssigned?: boolean;
    IdentificationRequired?: boolean;
    PhotoRequired?: boolean;
    HasSoldOut?: boolean;
    ApplyIndexing?: boolean;
    PassePartoutTypeId?: number | null;
    PrecedingPassePartoutId?: number | null;
    KeepPrecedingBarcode?: boolean;
    CancelableFromShop?: boolean;
    CancelableOrderedFrom?: Date | null;
    CancelableOrderedUntil?: Date | null;
    HardcardTemplateId?: number | null;
    ExtraInfo?: string | null;
    MobileTicketFrontTemplateId?: number | null;
    MobileTicketBackTemplateId?: number | null;
    MaxResell?: number | null;
    MaxSwap?: number | null;
    LimitResell?: boolean;
    AdditionalAttribute?: string | null;
    ShowMobileTicketInTicketshop?: boolean;
    MaxSeasonCardAmount?: number | null;
    LimitSeasonCardAmount?: boolean;
}

